// import RiveComponent, { Alignment, Fit, Layout } from "@rive-app/react-canvas"
import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useRef } from "react"
import { Col, Container, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Fade from "react-reveal/Fade"
import BadgesV2 from "../components/common/badges/BadgesV2.js"
import Buttons from "../components/common/button.js"
import CarouselComponent from "../components/common/carousel-component.js"
import CTA from "../components/common/CTA.js"
import Frame from "../components/common/frame.js"
import Integrations from "../components/common/integration-tools-new.js"
import LatestResourcesV2 from "../components/common/Latest-Resources-Section/LatestResourcesV2.js"
import ScrollContainerV3 from "../components/common/Scrolling-container/ScrollingContainerV3.js"
import SVGIcon from "../components/common/SVGIcon.js"
import TestimonialV5 from "../components/common/testimonial-component/multiple-5.js"
import YoutubePlayer from "../components/common/Youtube-player.js"
import Navigation from "../components/navigation.js"
import "../styles/pages/home-2023.scss"

const NewHome = props => {
  const responsiveAll = {
    allScreen: {
      breakpoint: { max: 4000, min: 0 },
      items: 1.3,
      slidesToSlide: 1,
    },
  }

  const DropdownContents = props => {
    let { el } = props
    return (
      <div className="position-relative">
        <div className="link-box br10 position-relative d-flex">
          {
            <>
              {el.icon && (
                <div className="icon-wrapper">
                  <img
                    decoding="async"
                    className="icon"
                    src={el.icon?.url}
                    alt="icon"
                  />
                </div>
              )}
              <div className="position-relative">
                {el.isNew === "Yes" && (
                  <p className="tag position-absolute m-0">NEW</p>
                )}
                <div className="p16">
                  <p className="title text-deep-purple fw-500">
                    {el.heading}
                    {props.rightArrow && (
                      <SVGIcon
                        name="hypertext-arrow-right"
                        className="arrow-right position-absolute"
                      />
                    )}
                  </p>
                </div>
                <div className="p14">
                  <p className="subtext text-grey m-0">{el.subText}</p>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  const setSessStorage = id => {
    if (id) sessionStorage.setItem("pageBlockScrollId", id)
  }

  const scrollArrRef = useRef()

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  const handleVisibility = () => {
    const BadgeSection = document.getElementById("badges-id")
    const screenHeight = window.innerHeight

    if (screenHeight > BadgeSection.getBoundingClientRect().top + 10) {
      scrollArrRef.current.style.opacity = 0
      scrollArrRef.current.style.visibility = "hidden"
    }
  }

  useEffect(() => {
    const BadgeSection = document.getElementById("badges-id")
    const screenHeight = window.innerHeight
    if (screenHeight < BadgeSection.offsetTop) {
      scrollArrRef.current.style.opacity = 1
      scrollArrRef.current.style.visibility = "visible"
    }
  }, [])

  const scrollToBadge = () => {
    window.scrollTo({
      top: window.innerHeight - 200,
      behavior: "smooth",
    })
    scrollArrRef.current.style.opacity = 0
    scrollArrRef.current.style.visibility = "hidden"
  }

  return (
    <div className="home-2023">
      <StaticQuery
        query={graphql`
          query newHome {
            SuperOps {
              pages(where: { title: "HomeV6.0" }) {
                title
                linkTitle
                heroImage {
                  url
                }
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                links {
                  authorName
                  linkName
                  icon {
                    url
                  }
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                pageImages {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
                navigationBlock {
                  colors {
                    hex
                  }
                  navigationLinks {
                    heading
                    subText
                    link
                    image {
                      url
                    }
                    childFooter
                    childFooterLink
                    featureNavigationLinks {
                      heading
                      subText
                      link
                      icon {
                        url
                      }
                      iconBgColor {
                        css
                      }
                      childHeader
                    }
                  }
                }
                ctaBoxes {
                  type
                  theme
                  customBackgroundColor
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                  card {
                    cardName
                    title
                    subtext
                    slug
                  }
                }
                faqComponent {
                  heading
                  description
                  faqType
                  faqs {
                    category
                    question
                    answerrt {
                      html
                    }
                    images {
                      url
                    }
                    faqImage {
                      url
                    }
                    slug
                    linkText
                    imageBlocks {
                      blockImageTitle
                      blockImage {
                        url
                      }
                      blockIsVideo
                      blockIsIllustration
                    }
                  }
                  ctaHeading
                  ctaText {
                    html
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    primaryButtonText
                    primaryButtonLink
                    secondaryButtonText
                    secondaryButtonLink
                    heroCtaText
                    heroExcerptList
                    headingLine1
                    animAltText
                    headingLine2
                    heroImage {
                      url
                    }
                    backgroundImage {
                      url
                    }
                  }
                  ... on SuperOps_Testimonial {
                    testimonialDatas {
                      title
                      name
                      content
                      bgColor
                      image {
                        url
                      }
                    }
                    testimonialTitle
                    testimonialName
                    testimonialDescription
                    testimonialContent
                    testimonialLink
                    testimonialImage {
                      url
                    }
                  }
                  ... on SuperOps_Card {
                    title
                    heading
                    cardName
                    cardDescription
                    slug
                    links
                    text
                    subtext
                    image {
                      url
                    }
                  }
                  ... on SuperOps_IntegrationComponent {
                    __typename
                    integrationHeading: heading {
                      text
                    }
                    integrationImage {
                      url
                    }
                    seoPosts {
                      __typename
                      slug
                      integrationCards {
                        cardName
                        title
                        subtext
                        slug
                        image {
                          url(
                            transformation: {
                              document: { output: { format: webp } }
                            }
                          )
                        }
                      }
                    }
                  }
                }
              }
              peopleList(where: { page_contains: "Home" }) {
                page
                name
                title
                testimonial
                background
                cardBgColor
                thumbnail {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 110 } }
                    }
                  )
                }
                picture {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 500 } }
                    }
                  )
                }
                titleText {
                  html
                }
                descriptionText {
                  html
                }
              }
              navigationContents {
                navigation
                section
                parentTitle
                parentDescription
                childTitle
                childSlug
                childDescription
                childIcon {
                  url
                }
                parentIllustration {
                  url
                }
                boxBg
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const {
                linkTitle,
                pageBlock,
                navigationBlock,
                seo,
                pageImages,
                ctaBoxes,
                newField,
              } = page
              const heroSection = newField[0]
              const videoSection = newField[1]
              const allIntegrations = newField[3]
              const navigationLinks = navigationBlock[0].navigationLinks
              const navigationColors = navigationBlock[0].colors

              return (
                <div>
                  <Frame
                    title={seo.title}
                    description={seo.description}
                    keywords={seo.keywords}
                    ogTitle={seo.title}
                    ogDescription={seo.description}
                    ogImage={seo.image}
                    ogType="website"
                    footerClass={"p-0"}
                    newButton
                  >
                    <header>
                      <Navigation
                        page="Home"
                        color="#FFE4E0"
                        dskScroll={400}
                        newButton
                      />
                    </header>
                    <main>
                      <section className="hero justify-content-center ">
                        <div className="bg-lines">
                          <Container className="Layout-container prelative">
                            <Row className="hero-row justify-content-between  p24">
                              <Col lg={5} className="text-deep-purple mt100">
                                <h1 className="v1 fw-bold mb38 main-title down-to-up-1">
                                  {heroSection.headingLine1}
                                </h1>

                                <div className="fw-600 mb64 down-to-up-4 main-description">
                                  <span className="m-0">
                                    {heroSection.heroExcerptList[0]}
                                  </span>
                                  <span className="m-0 text-pink">
                                    {heroSection.heroExcerptList[1]}
                                  </span>
                                  <span>{heroSection.heroExcerptList[2]}</span>
                                </div>

                                <div className="buttons prelative down-to-up-5">
                                  <Buttons
                                    theme="primary-new"
                                    link={heroSection.primaryButtonLink}
                                    text={heroSection.primaryButtonText}
                                  />
                                  <Buttons
                                    theme="secondary-new"
                                    link={heroSection.secondaryButtonLink}
                                    text={heroSection.secondaryButtonText}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div
                                  className={`hero-image-wrapper text-center prelative down-to-up-3`}
                                >
                                  <img
                                    src={heroSection.heroImage.url}
                                    alt="image"
                                    className="w-100 prelative z-1 image"
                                  />
                                  <SVGIcon
                                    name="block/blueCurveWire"
                                    className="position-absolute blue-line d-dsk"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="d-flex justify-content-center prelative d-dsk scroll-btn-wrap">
                              <div
                                ref={scrollArrRef}
                                className={"scroll-btn position-absolute"}
                                onClick={() => scrollToBadge()}
                              >
                                <SVGIcon
                                  name="block/downArrowRed"
                                  className="down-arrow-red"
                                />
                              </div>
                            </div>
                          </Container>
                        </div>
                      </section>

                      <section
                        className="badge-section mx-auto mb0 down-to-up-3"
                        id="badges-id"
                      >
                        <BadgesV2
                          heading={parse(pageBlock[0].content.text)}
                          images={pageImages}
                        />
                      </section>

                      <LazyLoad>
                        <TestimonialV5
                          testimonial={newField[2].testimonialDatas}
                        />
                      </LazyLoad>

                      <section className="home-video-section">
                        <Fade bottom duration={1000}>
                          <LazyLoad>
                            <div className="Layout-container mx-auto">
                              <Row className="d-flex justify-content-between row-div br-10">
                                <Col lg={5}>
                                  <h3 className="font-roboto fw-bold mb50 d-mob">
                                    {videoSection.cardName}
                                  </h3>
                                  <YoutubePlayer
                                    videoURL={videoSection.slug}
                                    videoScreenshot={{
                                      url: videoSection.image[0].url,
                                    }}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <h3 className="font-roboto fw-bold mb50 d-dsk">
                                    {videoSection.cardName}
                                  </h3>
                                  <div className="benefit-parent d-flex flex-wrap mt50">
                                    {videoSection.title.map(el => {
                                      return (
                                        <div className="benefits d-flex align-items-center">
                                          <SVGIcon
                                            name="greenTick"
                                            className="tick-logo"
                                          />
                                          <div className="p16">
                                            <p className="name m-0 fw-500">
                                              {el}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </LazyLoad>
                        </Fade>
                      </section>

                      <div className="feature-integration">
                        <section className="feature-tabs m-0 prelative">
                          <center>
                            <Fade bottom duration={1000}>
                              <Container className="Layout-container">
                                <div className="main-title fw-bold">
                                  {parse(pageBlock[1].content.html)}
                                </div>

                                <div className="p18 mt16 text-deep-purple">
                                  {parse(pageBlock[2].content.html)}
                                </div>
                              </Container>
                            </Fade>
                          </center>

                          <div className="d-flex justify-content-between flex-column">
                            <div className="d-mob">
                              {navigationLinks.map((el, id) => {
                                const featureNavigationLinks =
                                  el.featureNavigationLinks
                                const tagColors = [
                                  "#D67FBF",
                                  "#8E97E1",
                                  "#4ACDCD",
                                  "#FE7D7D",
                                ]
                                return (
                                  <>
                                    <div className="img-wrapper mx-auto mb30 prelative">
                                      <img
                                        decoding="async"
                                        src={el.image.url}
                                        alt="featureImage"
                                        className="w-100"
                                      />
                                      <div
                                        className="linear-bg position-absolute"
                                        style={{
                                          background: `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, ${navigationColors[id].hex} 35.94%, white 100%)`,
                                        }}
                                      ></div>
                                    </div>

                                    <Container className="Layout-container">
                                      <div className="p12 mt24 mb16 fw-600">
                                        <p
                                          className="m-0 d-inline-block tag"
                                          style={{
                                            background: `linear-gradient(90deg, ${tagColors[id]} -60%, rgba(255, 255, 255, 0.00) 80%)`,
                                          }}
                                        >
                                          {el.heading}
                                        </p>
                                      </div>

                                      <div className="altv1 heading-wrapper">
                                        <h4 className="fw-600 m-0 mt40 font-roboto">
                                          {el.subText}
                                        </h4>
                                      </div>
                                      <div className="prelative mt38 mb64">
                                        <LazyLoad>
                                          <div className="feature-links">
                                            <CarouselComponent
                                              responsive={responsiveAll}
                                              swipeable
                                              customButtonNew
                                              partialVisbile
                                              customButtonclass="home-carousel"
                                            >
                                              {featureNavigationLinks
                                                .slice(0, 4)
                                                .map((el2, idx2) => {
                                                  if (idx2 < 4) {
                                                    return el2.link ? (
                                                      <div
                                                        onClick={() =>
                                                          setSessStorage(
                                                            el2.childHeader
                                                          )
                                                        }
                                                      >
                                                        <Link to={el2.link}>
                                                          <DropdownContents
                                                            el={el2}
                                                            rightArrow
                                                          />
                                                        </Link>
                                                      </div>
                                                    ) : (
                                                      <DropdownContents
                                                        el={el2}
                                                      />
                                                    )
                                                  }
                                                })}
                                            </CarouselComponent>
                                          </div>
                                          <Link
                                            to={`/${el.childFooterLink}`}
                                            className="d-flex align-items-center title-link fw-500 position-absolute more-link mt32"
                                          >
                                            {el.childFooter}
                                            <SVGIcon
                                              name="hypertext-arrow-right"
                                              className="arrow-right"
                                            />
                                          </Link>
                                        </LazyLoad>
                                      </div>
                                    </Container>
                                  </>
                                )
                              })}
                            </div>
                          </div>
                          <div className="d-dsk">
                            <LazyLoad>
                              <ScrollContainerV3
                                data={navigationLinks}
                                leftColumn={6}
                                rightColumn={6}
                                topValue="280"
                                colors={navigationColors}
                              />
                            </LazyLoad>
                          </div>
                        </section>
                      </div>

                      <section className="integrations-new">
                        <div className="color-bg mobile">
                          <Container className="Layout-container position-relative d-flex align-items-center">
                            <div className="color-bg desk w-100 h-100 position-absolute d-dsk"></div>
                            <Row className="parent align-items-center justify-content-center">
                              <Col lg={6}>
                                <div className="d-dsk">
                                  <Integrations
                                    data={allIntegrations.seoPosts}
                                  />
                                </div>
                                <div className="d-mob">
                                  <img
                                    src={allIntegrations.integrationImage.url}
                                    alt="integrations"
                                    className="w-100 integ-img"
                                  />
                                </div>
                              </Col>
                              <Col lg={4}>
                                <Fade bottom duration={800} distance="30px">
                                  <p className="text-pink p12 fw-600 tag">
                                    MARKETPLACE
                                  </p>
                                  <h3 className="font-roboto fw-bold mb38">
                                    {allIntegrations.integrationHeading.text}
                                  </h3>
                                  <Buttons
                                    theme="secondary-new"
                                    link={"/marketplace"}
                                    text={"VIEW THEM ALL"}
                                  />
                                </Fade>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </section>

                      <section className="cta normal-cta">
                        <LazyLoad>
                          <CTA
                            data={[ctaBoxes[0]]}
                            className="Layout-container"
                            maxDescriptionWidth="500px"
                            lgLeft={6}
                            lgRight={3}
                            newButton
                            newDesign
                          />
                        </LazyLoad>
                      </section>

                      <section className="">
                        <Container className="Layout-container">
                          <LazyLoad>
                            <LatestResourcesV2
                              heading="Blogs, podcasts, templates, and more just for you"
                              leftColData={newField[4]}
                            />
                          </LazyLoad>
                        </Container>
                      </section>

                      <section className="cta green-cta">
                        <LazyLoad>
                          <CTA
                            data={[ctaBoxes[1]]}
                            bgPattern
                            className="Layout-full"
                            maxDescriptionWidth="475px"
                            maxBoxWidth="100%"
                            maxRowWidth="1176px"
                            lgLeft={5}
                            lgRight={7}
                            newButton
                            newDesign
                          />
                        </LazyLoad>
                      </section>
                    </main>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default NewHome
